@import "variables";
@import "account/passwordRequirements";
@import "components/formFields";

.account-auth_col:first-of-type {
    border-right: 1px solid $login-separator;
}

.account-auth_content {
    max-width: 24rem;
    width: 100%;

    &.track {
        max-width: 45rem;
    }
}

.birthday_field_description {
    font-size: 11px;
    line-height: 13px;
    color: #818181;
    text-align: right;
    margin: 5px 0 0;
    width: 100%;
}

.migration-password-reset {
    margin-left: auto;
    margin-right: auto; 
    margin-top: 50px; 
    margin-bottom: 50px;
}